(function($, ns) {
  "use strict";

  var cfg = {
    cache: {
      container: '[data-component="syte.series"]',
      seriesBody: ".series-body"
    },
    classes: {
      loading: "loading"
    },
    data: {
      seriesId: "series-id"
    },
    events: {},
    options: {},
    scripts: {
      seriesCall: `${window.location.protocol}//${
        document.location.hostname
      }/wp-json/volley_api/series/{seriesId}`
    },
    template: {
      rowEntry:
        "<tr>" +
        '<th scope="row">{{Ranking}}</th>' +
        "<td>{{Name}}</td>" +
        "<td>{{Points}}</td>" +
        "<td>{{Games}}</td>" +
        "<td>{{Won}}</td>" +
        "<td>{{SemiWon}}</td>" +
        "<td>{{Lost}}</td>" +
        "<td>{{SemiLost}}</td>" +
        "<td>{{SetsWon}}</td>" +
        "<td>{{SetsLost}}</td>" +
        // "<td>{{Forfaits}}</td>" +
        "</tr >"
    }
  };

  ns.Series = function(container) {
    this.container = container;
    this.settings = cfg;
    this.init();
  };

  ns.Series.prototype = {
    init: function() {
      if (this.container.length) {
        this.cacheItems();
        this.bindEvents();
        this.activate();
      }
    },
    cacheItems: function() {
      var cache = this.settings.cache;

      this.win = $(window);

      this.container = $(cache.container);

      this.results = this.container.find(cache.results);
      this.home = this.container.find(cache.home);
      this.guests = this.container.find(cache.guests);
      this.datetime = this.container.find(cache.datetime);
    },
    bindEvents: function() {},
    activate: function() {
      this.getLastMatches();
    },

    getLastMatches: function() {
      var settings = this.settings,
        classes = settings.classes,
        data = settings.data,
        scripts = settings.scripts;

      var self = this;

      var seriesId = this.container.data(data.seriesId);
      var seriesCall = scripts.seriesCall.replace("{seriesId}", seriesId);

      this.container.addClass(classes.loading);
      $.ajax({
        type: "GET",
        url: seriesCall,
        success: function(data) {
          if (data.main) {
            self.setDataToTable(self, data);
          }
        }
      }).done(function() {
        self.container.removeClass(classes.loading);
      });
    },

    setDataToTable: function(self, data) {
      var rowEntry = self.settings.template.rowEntry;
      var tbody = self.container.find(self.settings.cache.seriesBody);
      for (var i = 0; i < data.main.length; i++) {
        var t = data.main[i];

        var teamData = {
          Ranking: t.ranking,
          Name: self.stripSeriesFromTeam(t.name),
          Points: t.points,
          Games: t.games,
          Won: t.won,
          SemiWon: t.semi_won,
          Lost: t.lost,
          SemiLost: t.semi_lost,
          SetsWon: t.sets_won,
          SetsLost: t.sets_lost
          // Forfaits: t.forfaits
        };
        var row = $(ns.fn.renderTemplate(teamData, rowEntry));
        if (self.checkIfHomeTeam(t.name))
          row.addClass("series table-home-team");

        tbody.append(row);
      }
    },

    stripSeriesFromTeam: function(teamName) {
      return teamName.substring(0, teamName.indexOf("("));
    },

    checkIfHomeTeam: function(teamName) {
      return teamName.indexOf("LEEFDAAL") >= 0;
    }
  };

  return ns;
})(window.jQuery, window.Syte || {});
